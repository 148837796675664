import { Admin,Editor } from "@/router";
export default [
    {
        title: "الرئيسية",
        route: "/admin/home",
        icon: "home-alt",
        roles: [Admin]
    },
    {
        header: "الأقسام الرئيسية",
        roles: [Admin]
    },
    {
        title: "المنتجات",
        route: "/admin/products",
        icon: "box",
        roles: [Admin]
    },
    {
        title: "النشاطات",
        route: "/admin/activities",
        icon: "invoice",
        roles: [Admin, Editor]
    },
    {
        title: "الطلبات",
        route: "/admin/orders",
        icon: "wheel-barrow",
        roles: [Admin]
    },
    {
        title: "الخدمات",
        route: "/admin/services",
        icon: "briefcase-alt",
        roles: [Admin, Editor]
    },
    {
        title: "الزبائن",
        route: "/admin/customers",
        icon: "user-circle",
        roles: [Admin]
    },
    {
        title: "الموظفين",
        route: "/admin/employees",
        icon: "users-alt",
        roles: [Admin,Editor]
    },
    {
        title: "بريد الشركة",
        route: "/admin/contacts",
        icon: "envelope",
        roles: [Admin]
    },
    {
        header: "الإعدادات",
        roles: [Admin]
    },
    {
        title: "حسابات المستخدمين",
        route: "/admin/accounts",
        icon: "users-alt",
        roles: [Admin]
    },
    {
        title: "التصنيفات",
        route: "/admin/categories",
        icon: "building",
        roles: [Admin]
    },
    {
        title: "إعدادات الشركة",
        route:'/admin/settings',
        icon: "cog",
        roles: [Admin]
    },
    
    
];
