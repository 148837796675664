<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- <app-navbar-vertical-layout class="ml-auto" /> -->

    <!-- Left Col -->
    <app-navbar-vertical-layout />
    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-button
        @click="changeDashLang(locales[+activeIndex].locale)"
        variant="flat-dark"
        class="btn-icon rounded border-0"
        style="font-size: 12px"
      >
        {{ locales[+activeIndex].name }}
      </b-button>
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import { BLink, BNavbarNav } from "bootstrap-vue";
import AppNavbarVerticalLayout from "@core/layouts/components/app-navbar/AppNavbarVerticalLayout.vue";
import UserDropdown from "@core/layouts/components/app-navbar/components/UserDropdown.vue";
import globalEvent from "@/libs/global-event";
import { getDashLang, setDashLang } from "@/libs/i18n";
import { getUserData } from "@/auth/utils";
import { Admin } from "@/router";
import { mapActions } from "vuex";
export default {
  components: {
    BLink,
    BNavbarNav,

    // Navbar Components
    AppNavbarVerticalLayout,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    const locales = [
      {
        locale: "en",
        name: "الانكليزية",
        dir: "ltr",
      },
      {
        locale: "ar",
        name: "العربية",
        dir: "rtl",
      },
    ];
    return {
      locales,
    };
  },
  data: () => ({
    activeIndex: 0,
  }),
  created() {
    this.changeDashLang(getDashLang());
    this.activeIndex = +!(this.currentLocale.locale == "ar");
  },
  computed: {
    currentLocale() {
      return this.locales.find((l) => l.locale === this.$i18n.locale);
    },
  },
  methods: {
    ...mapActions(["app/setDashLang"]),
    isAdmin() {
      return (
        getUserData()["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"] ==
        Admin
      );
    },
    changeDashLang(locale) {
      this.activeIndex = !this.activeIndex;
      setDashLang(locale);
      this.$i18n.locale = locale;
      globalEvent.changeDashLang();
    },
  },
};
</script>
